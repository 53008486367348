<template>
    <div class="class-admin">
      <div class="class-admin-header">
        <el-button type="primary" @click="addClassBtn">创建班级</el-button>
      </div>
      <el-table class="customTable" :data="classList" style="width: 100%; margin-top: 20px; flex: 1;border: 1px solid #EEEEEE;" height="1%" size="medium"
                :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666666', background: '#F6F6F6',fontSize: '16px'}"
                :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
        <el-table-column prop="name" align="center" label="班级名称"></el-table-column>
        <el-table-column prop="num" align="center" label="班级人数"></el-table-column>
        <el-table-column prop="create_time" align="center" label="创建时间"></el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-link type="success" :underline="false" @click="editData(scope.row.id)">编辑</el-link>
            <el-link type="danger" :underline="false" @click="deleteData(scope.row.id)">删除</el-link>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :title="dialogTitle"
          custom-class="green-dialog"
          :visible.sync="addDialogVisible"
          @close="resetForm"
          @opened="openAddClass"
          width="500px">
        <el-form ref="addForm" :model="addForm" :rules="addFormRules" label-position="top">
          <el-form-item label="班级名称" prop="name">
            <el-input v-model="addForm.name" ref="cursorInput" placeholder="2-18"></el-input>
          </el-form-item>
        </el-form>
        <div class="dialog-footer">
          <el-button  @click="addDialogVisible = false">取 消</el-button>
          <el-button style="margin-left: 30px" type="primary" @click="confirmForm('addForm')">确 定</el-button>
        </div>
      </el-dialog>
      <el-pagination class="pager-center" style="margin: 20px;text-align: center"
                     :current-page="listPages.currentPageNum"
                     :page-size="listPages.eachPageNum"
                     :total="listPages.total"
                     layout="prev, pager, next, jumper"
                     @current-change="listCurrentChange">
      </el-pagination>
    </div>
</template>

<script>
import { getClassesList, classesAdd, classesInfo, classesDel } from "@/utils/apis";

export default {
  name: "ClassManagement",
  data(){
    return {
      classList:[],
      listPages:{
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0,
      },
      addDialogVisible:false,
      dialogTitle:'',
      addForm:{
        id:'',
        name:''
      },
      addFormRules:{
        name:[
          { required: true, message: '请输入班级名称', trigger: 'blur' },
          { min: 2, max: 18, message: '长度在 2 到 18 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    this.getClassList()
  },
  methods:{
    getClassList(){
      let params = {
        page:this.listPages.currentPageNum,
        limit:this.listPages.eachPageNum
      }
      getClassesList(params).then((res)=>{
        this.classList = res.data.list;
        this.listPages.total = res.data.total;
      }).catch((err)=>{
        console.log('err', err)
      })
    },
    resetForm(){
      this.$refs.addForm.resetFields();
      this.addForm.id = '';
    },
    openAddClass(){
      if(this.addForm.id){
        classesInfo(this.addForm.id).then((res)=>{
          this.addForm.name = res.data.name;
        }).catch((err)=>{
          console.log('err',err)
        })
      }
      this.$refs.cursorInput.focus();
    },
    addClassBtn(){
      this.dialogTitle = '创建班级';
      this.addDialogVisible = true;
    },
    confirmForm(formName){
      this.$refs[formName].validate((valid)=>{
        if(valid){
          let formData = new FormData();
          formData.append('name', this.addForm.name)
          if(this.addForm.id){
            formData.append('id', this.addForm.id);
          }
        classesAdd(formData).then((res)=>{
          this.$message({
            message:res.msg,
            type:'success',
            duration:1000,
            onClose: ()=>{
              this.addDialogVisible = false;
              this.getClassList()
            }
          })
        }).catch((err)=>{
          console.log('err', err)
        })
        } else {
          return false;
        }
      })
    },
    editData(id){
      this.addForm.id = id;
      this.dialogTitle = '编辑学校';
      this.addDialogVisible = true
    },
    deleteData(id){
      this.$confirm(`是否删除该班级，删除将无法恢复`, '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass:'green-message-box',
        type:'warning'
      }).then(()=>{
        classesDel(id).then((res)=>{
          this.$message({
            message:res.msg,
            type:'success',
            duration:1000,
            onClose: ()=>{
              this.getClassList()
            }
          })
        }).catch((err)=>{
          console.log('err', err)
        })
      }).catch(()=>{
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      })
    },
    listCurrentChange(val){
      this.listPages.currentPageNum = val;
      this.getClassList();
    }
  }
}
</script>

<style scoped lang="scss">
.class-admin{
  padding: 0px 20px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  .class-admin-header{
    display: flex;
    justify-content: flex-end;
  }
}
</style>